const data = {"text": "Wat zijn voor mij argumenten voor en tegen eicellen invriezen?","items": [{
    "text": "Resultaat",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling geeft me de kans een genetisch eigen kind te krijgen",
            "items": [{
                "text": "Ik vergroot mijn kans op een kind."
            }, {
                "text": "Hoe jonger ik ben als ik mijn eicellen laat invriezen, hoe groter de kans op een succesvolle zwangerschap."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling geeft geen garantie op succes",
            "items": [{
                "text": "Ik moet voor een succesvolle vruchtbaarheidsbehandeling meerdere eicellen kunnen laten invriezen."
            }, {
                "text": "Ik heb geen garantie op succes van invriezen. "
            }, {
                "text": "Ik heb geen garantie op een levend geboren kind."
            }, {
                "text": "Er is vooraf weinig te zeggen over de kansen op een succesvolle behandeling en zwangerschap."
            }, {
                "text": "Er is meer ervaring met embryo's invriezen dan met eicellen invriezen."
            }]
        }]
    }]
}, {
    "text": "Gezondheid",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling biedt me de kans om de endometriose grondiger aan te pakken",
            "items": [{
                "text": "Als ik eicellen invries kan ik een uitgebreidere endometrioseoperatie overwegen waarbij mijn eierstokken worden verwijderd."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kan schadelijk zijn voor mijn gezondheid    ",
            "items": [{
                "text": "Ik moet hormoonstimulaties ondergaan, dit kan de endometriose verergeren."
            }]
        }]
    }]
}, {
    "text": "Kosten",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De verzekering dekt mogelijk een deel van de kosten",
            "items": [{
                "text": "Ik hoef de behandeling mogelijk niet zelf te betalen."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kost geld",
            "items": [{
                "text": "Ik moet de kosten van de behandeling misschien zelf betalen."
            }, {
                "text": "Ik moet de kosten voor het invriezen en ingevroren houden van de eicellen zelf betalen."
            }, {
                "text": "Ziekenhuiszorg gaat af van het wettelijke eigen risico en mogelijk geldt een eigen bijdrage voor medicatie. "
            }]
        }]
    }]
}, {
    "text": "Behandeling",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling past bij mijn situatie op dit moment",
            "items": [{
                "text": "Ik heb geen partner nodig voor deze behandeling."
            }, {
                "text": "Ik hou mijn opties open en kan later beslissen of ik de eicellen wil gebruiken."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kost veel tijd en is voor mij lastig te plannen",
            "items": [{
                "text": "Ik moet vaak en op moeilijk planbare tijden naar het ziekenhuis voor echo's en een punctie."
            }, {
                "text": "Als er niet genoeg eicellen verzameld kunnen worden moet de behandeling worden herhaald."
            }]
        }, {
            "text": "De behandeling is lichamelijk en psychisch zwaar",
            "items": [{
                "text": "Ik kan klachten krijgen zoals buikpijn, misselijkheid, hoofdpijn en vaginaal bloedverlies."
            }, {
                "text": "De punctie om de eicellen te prikken is pijnlijk en geeft een klein risico op infecties en bloedingen."
            }, {
                "text": "De hormoonstimulaties en controles van het rijpen van de eiblaasjes zijn stressvol."
            }, {
                "text": "De hormoonstimulaties maken me prikkelbaar."
            }]
        }, {
            "text": "Ik voel me niet prettig bij deze behandeling    ",
            "items": [{
                "text": "Ik wil geen extra medicatie gebruiken als dat niet noodzakelijk is."
            }, {
                "text": "Ik ben bang voor naalden."
            }]
        }]
    }]
}, {
    "text": "Overtuiging",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "Ook als ik de eicellen niet kan of wil gebruiken kan ik er iets goeds mee doen",
            "items": [{
                "text": "Als ik ervoor kies de eicellen niet te gebruiken kan ik ze doneren, zoals aan de wetenschap."
            }]
        }, {
            "text": "De behandeling past bij mijn levensovertuiging",
            "items": [{
                "text": "Ik mag van mijn religie geen embryo's invriezen, maar wel eicellen."
            }]
        }]
    }]
}]}

export default data
