import React from 'react';

import harmonica1 from './harmonica-1-opereren.js';
import harmonica2 from './harmonica-2-eicellen.js';
import harmonica3 from './harmonica-3-embryos.js';

import geslachtsorgaanBlauw from './geslachtsorgaanBlauw.svg';
import geslachtsorgaanOranje from './geslachtsorgaanOranje.svg';

export const pages = [{
    id: 0,
    template: 'frontpage',
    text: 'Deze Keuzehulp helpt bij het maken van keuzes over vruchtbaarheidsbehoud. De verschillende opties en belangrijkste argumenten staan overzichtelijk geordend. Deze Keuzehulp helpt bij het gesprek met arts of betrokkenen maar is géén instrument waaruit een beslissing volgt.',
    title: 'Wat zijn voor mij opties voor vruchtbaarheidsbehoud?',
    cards: [
        { id: 'card1', title: 'Eicellen invriezen', text: '', link: 2 },
        { id: 'card2', title: 'Embryo\'s invriezen', text: '', link: 3 }
    ]
}, {
    id: 1,
    slug: 'operaties',
    template: 'infopage',
    intro: {
        expandable: true,
        title: 'Toelichting',
        content:
            <div className="panel-box">
                <p>De keuze voor een behandeling voor vruchtbaarheid behoud is een optie, het is geen medische noodzaak. Het hangt van de individuele voorkeur, medische conditie en situatie af of je hiervoor wil kiezen.</p>
            </div>
    },
    harmonica: harmonica1,
    expandables: [0,1,2],
    closed: [1,2]
}, {
    id: 2,
    template: 'infopage',
    slug: 'eicellen',
    title: '',
    intro:  {
        expandable: true,
        title: 'Wat houdt het invriezen van eicellen in?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanOranje} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <ul>
                <li>Je moet jezelf twee tot drie weken onderhuids hormonen inspuiten om eiblaasjes te laten groeien.</li>
                <li>Een arts of verpleegkundige maakt meerdere vaginale echo's om te kijken of de eiblaasjes goed groeien.</li>
                <li>Als de eiblaasjes groot genoeg zijn haalt een arts ze met een naald via je vagina uit de eierstok.</li>
                <li>Tijdens de ingreep ben je wel wakker, maar vooraf krijg je een kalmerend en pijnstillend middel.</li>
                <li>Alleen de rijpe eicellen worden ingevroren.</li>
                <li>Als een natuurlijke zwangerschap uitblijft kunnen de eicellen worden ontdooid.</li>
                <li>De eicellen worden bevrucht in het laboratorium met zaadcellen van je partner of een donor.</li>
                <li>De embryo's worden in je baarmoeder geplaatst.</li>
            </ul>
        </div>
        },
    voorwaarden: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van eicellen?',
        content:
        <div className="panel-box">
            <ul>
                <li>Je mag voor de behandeling niet ouder zijn dan 40 jaar, je kan je eicellen gebruiken tot 50 jaar.</li>
                <li>Je moet lichamelijk en psychisch in staat zijn om vaginale echo's en een punctie te ondergaan.</li>
            </ul>
        </div>
    },
    harmonica: harmonica2,
    expandables: [0,1,2],
    closed: [0,1,2]
}, {
    id: 3,
    template: 'infopage',
    slug: 'embryos',
    title: '',
    intro:  {
        expandable: true,
        title: 'Wat houdt het invriezen van embryo\'s in?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanBlauw} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <ul>
                <li>Je moet jezelf twee tot drie weken onderhuids hormonen inspuiten om eiblaasjes te laten groeien.</li>
                <li>Een arts of verpleegkundige maakt meerdere vaginale echo's om te kijken of de eiblaasjes goed groeien.</li>
                <li>Als de eiblaasjes groot genoeg zijn haalt een arts ze met een naald via je vagina uit de eierstok.</li>
                <li>Tijdens de ingreep ben je wel wakker, maar vooraf krijg je een kalmerend en pijnstillend middel.</li>
                <li>Alleen de rijpe eicellen worden bevrucht in het laboratorium, waarna de embryo's worden ingevroren.</li>
                <li>Als een natuurlijke zwangerschap uitblijft kunnen de embryo's worden ontdooid.</li>
                <li>De embryo's worden in je baarmoeder geplaatst.</li>
            </ul>
        </div>
        },
    voorwaarden: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van embryo\'s?',
        content:
        <div className="panel-box">
        <ul>
            <li>Je moet een mannelijke partner of donor hebben die toestemt met behandeling en plaatsing van de embryo's.</li>
            <li>Je mag voor de behandeling niet ouder zijn dan 40 jaar, je kan je embryo's gebruiken tot 50 jaar.</li>
            <li>Je moet lichamelijk en psychisch in staat zijn om meerdere vaginale echo's en een punctie te ondergaan.</li>
        </ul>
        </div>
    },
    harmonica: harmonica3,
    expandables: [0,1,2],
    closed: [0,1,2]
    }
];
