const data = {
    "text": "Wat zijn voor mij argumenten voor en tegen een behandeling voor vruchtbaarheidsbehoud? ",
    "items": [{
        "text": "Resultaat",
        "items": [{
            "text": "Argumenten voor",
            "items": [{
                "text": "Een behandeling voor vruchtbaarheidsbehoud geeft me de kans een genetisch eigen kind te krijgen",
                "items": [{
                    "text": "Ondanks mijn endometriose behoud ik dankzij de behandeling een kans op vruchtbaarheid."
                }, {
                    "text": "Als ik wacht is kans dat de endometriose toeneemt groter en nemen mijn kansen op een succesvolle vruchtbaarheidsbehandeling af."
                }]
            }, {
                "text": "Ik hoef nu geen keuze te maken over kinderen",
                "items": [{
                    "text": "Door de behandeling heb ik langer de tijd om te besluiten of ik wel of geen kinderen wil."
                }, {
                    "text": "Ik ben er nog niet aan toe om mijn kinderswens af te sluiten."
                }, {
                    "text": "Mijn partner wil dat ik aan vruchtbaarheidsbehoud doe zodat we een kans op een kind houden."
                }, {
                    "text": "Ik vind mezelf te jong om nu al te beslissen over kinderen."
                }]
            }]
        }, {
            "text": "Argumenten tegen",
            "items": [{
                "text": "Een behandeling is niet nodig",
                "items": [{
                    "text": "Mijn kansen op een natuurlijke zwangerschap zijn hoog, een behandeling is niet nodig."
                }, {
                    "text": "Ik kan ook op andere manieren kinderen krijgen, zoals door eiceldonatie of adoptie."
                }, {
                    "text": "Ik weet niet of vruchtbaarheidsbehoud nodig is, omdat ik niet weet wat mijn kansen zijn op een natuurlijke zwangerschap."
                }]
            }]
        }]
    }, {
        "text": "Gezondheid",
        "items": [{
            "text": "Argumenten tegen",
            "items": [{
                "text": "Geen vruchtbaarheidsbehoud is mogelijk beter voor mijn gezondheid    ",
                "items": [{
                    "text": "Ik hoef geen hormoonstimulaties te ondergaan die mijn endometriose kunnen verergeren."
                }, {
                    "text": "Ik wil niet stoppen met de therapie die de endometriose onderdrukt."
                }, {
                    "text": "Het is lichamelijk en psychisch zwaar om bezig te zijn met een zwangerschap die niet direct tot stand komt."
                }, {
                    "text": "Een natuurlijke zwangerschap geeft minder risico's op mijn gezondheid en die van mijn kind."
                }]
            }]
        }]
    }, {
        "text": "Kosten",
        "items": [{
            "text": "Argumenten tegen",
            "items": [{
                "text": "Niets doen kost geen geld",
                "items": [{
                    "text": "Dit kost mij en de maatschappij niets."
                }]
            }]
        }]
    }, {
        "text": "Gevoel",
        "items": [{
            "text": "Argumenten voor",
            "items": [{
                "text": "Een behandeling geeft me rust en een gevoel van zekerheid",
                "items": [{
                    "text": "Een behandeling geeft me het gevoel dat ik alles heb gedaan om kinderen te kunnen krijgen."
                }]
            }, {
                "text": "Vruchtbaarheidsbehoud geeft me een gevoel van zelfstandigheid",
                "items": [{
                    "text": "Met een behandeling heb ik het gevoel dat ik zelf de regie hou over mijn vruchtbaarheid."
                }]
            }]
        }, {
            "text": "Argumenten tegen",
            "items": [{
                "text": "Niets doen geeft me een gevoel van zelfstandigheid",
                "items": [{
                    "text": "Ik wil zoveel mogelijk regie over mijn zorg houden."
                }, {
                    "text": "Het geeft me rust om te accepteren dat ik geen genetisch eigen kind zal krijgen."
                }]
            }]
        }]
    }, {
        "text": "Overtuiging",
        "items": [{
            "text": "Argumenten tegen",
            "items": [{
                "text": "Ik wil geen behandeling uit persoonlijke overtuiging",
                "items": [{
                    "text": "Ik wil geen kinderen."
                }, {
                    "text": "Ik wil vanwege mijn geloof geen behandeling ondergaan."
                }, {
                    "text": "Ik wil geen kind krijgen als hiervoor medische hulp nodig is."
                }]
            }, {
                "text": "Vruchtbaarheidsbehoud past op dit moment niet bij mijn leven",
                "items": [{
                    "text": "Ik wil mijn kinderwens (nog) niet bespreken met mijn partner."
                }, {
                    "text": "Mijn partner wil niet dat ik aan vruchtbaarheidsbehoud doe."
                }]
            }]
        }]
    }]
}

export default data
